import "./layout/main/main.css";
import fist from "./resources/images/fist.png";
import convenientImage from "./resources/images/convenient.png";
import experts from "./resources/images/experts.png";
import privacy from "./resources/images/privacy.png";
import user1 from "./resources/images/user1.jpeg";
import user2 from "./resources/images/user2.jpeg";
import user3 from "./resources/images/user3.jpeg";
import user4 from "./resources/images/user4.jpeg";
import logo from "./resources/images/logo.png";
import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <div className="WebContainer background">
        <div className="header">
          <Link to="/" id="link">
            <img className="a.logo" src={logo} width="250" height="50" alt="" />
          </Link>

          <div className="header-right-wrapper">
            <Link role="button" to="/SignUp" className="header-right">
              <a href="#SignIn">Sign In</a>
            </Link>
          </div>
        </div>

        <div className="home-page">
          <h1>Your Medical Data In Your Hands</h1>
          <p className="questions">
            Tired of waiting on the phone to book an appointment with the
            doctor?{" "}
          </p>
          <p className="questions">
            {" "}
            Want to view your test results at a time conveniant to you?{" "}
          </p>
          <p className="questions">
            {" "}
            All your medical data at your fingertips{" "}
          </p>
        </div>
        <br />
        <div className="button-wrapper">
          <Link role="button" to="/SignUp" className="signup-button">
            Try MedTech Today
          </Link>
        </div>
        <br />
        <div className="home-page">
          <h2>Why MedTech?</h2>
        </div>
        <div className="wrapper">
          <div className="box">
            <img src={fist} width="150" height="150" alt="" />
            <h2>Empowerment</h2>
            <p>
              Our aim is to empower you with the data you need to take control
              of your health
            </p>
          </div>
          <div className="box">
            <img src={convenientImage} width="150" height="150" alt="" />
            <h2>Conveniance</h2>
            <p>
              Manage your appointments and health at a time conveniant to you
            </p>
          </div>
          <div className="box">
            <img src={experts} width="150" height="150" alt="" />
            <h2>Specialists</h2>
            <p>
              We only work with fully qualified practitioners. <br /> The best
              in class
            </p>
          </div>
          <div className="box">
            <img src={privacy} width="150" height="150" alt="" />
            <h2>Privacy </h2>
            <p>
              All your data is encrypted. Only you can access it. <br /> Your
              privacy is our # 1 priority
            </p>
          </div>
        </div>
        <div className="home-page">
          <h2>People *really* like MedTech.</h2>
          <p className="questions">
            <i>Tens of thousands</i> of people have already joined the MedTech
            revolution.
          </p>
        </div>
        <div className="social-wrapper">
          <div className="social-profile">
            <p className="questions">
              "MedTech is a game changer. No more smashing the appointment
              booking line at 9am when the doctors open to constantly sit on
              redial. Now we can book times as and when we choose. MedTech for
              the win!"
            </p>
          </div>
          <img className="social-profile-img" src={user1} width="150" height="150" alt="" />
          <h2 className="social-profile-img-text">Teddy Mercury</h2>

          <div className="social-profile">
            <p className="questions">
              "That awkward conversation with the doctor is finally OVER! Get
              the information you need and get to the pharmacy to get it fixed."{" "}
            </p>
          </div>
          <img className="social-profile-img" src={user2} width="150" height="150" alt="" />
          <h2 className="social-profile-img-text">Ryan May</h2>

          <div className="social-profile">
            <p className="questions">
              "Going to the doctors and constantly going back and forth on
              extremely private issues is so old school. MedTech has got this
              sorted for the 21st Century!"
            </p>
          </div>
          <img className="social-profile-img" src={user3} width="150" height="150" alt="" />
          <h2 className="social-profile-img-text">Sandy Acott</h2>

          <div className="social-profile">
            <p className="questions">
              "Totally professional service from start to finish - all from the
              comfort of my sofa. RESULT"
            </p>
          </div>
          <img className="social-profile-img" src={user4} width="150" height="150" alt="" />
          <h2 className="social-profile-img-text">Rathan Noys</h2>
        </div>
        <br />
        <br />
        <div className="button-wrapper-wave">
          <Link role="button" to="/SignUp" className="signup-button">
            Try MedTech Today <span className="wave">👋</span>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Home;
