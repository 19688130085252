import { Amplify } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import GetAppointments from "./GetAppointments";
import SignUp from "./SignUp";
import axios from "axios";
import { useLocation, matchPath } from "react-router";

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: "eu-west-2:3ddf3e67-8c91-4a27-9d8d-539d4b3b3126",
    // REQUIRED - Amazon Cognito Region
    region: "eu-west-2",
    userPoolId: "eu-west-2_b3Zl2pzPy",
    userPoolWebClientId: "1mcc0u0n1296muv743atm33d5s",
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
});
const baseURL = "https://5l2jhnip6i.execute-api.eu-west-2.amazonaws.com/POC";

function ProtectedRoutes({ signOut, user }) {
  const { pathname } = useLocation();
  const isGetAppointments = matchPath("/GetAppointments/*", pathname);
  const isSignUp = matchPath("/SignUp/*", pathname);
  const client = axios.create({
    baseURL: baseURL,
    headers: {
      Authorization: `Bearer ` + user.signInUserSession.idToken.jwtToken,
    },
  });
  return (
    <>
      {isGetAppointments && (
        <GetAppointments signOut={signOut} user={user} client={client} />
      )}
      {isSignUp && (
        <SignUp signOut={signOut} user={user} client={client} />
      )}
    </>
  );
}
export default withAuthenticator(ProtectedRoutes);
