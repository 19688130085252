import react from "react";
import "@aws-amplify/ui-react/styles.css";
import "./layout/main/main.css";
import logo from "./resources/images/logo.png";
import { Link } from "react-router-dom";
import CreateAppointment from "./CreateAppointment";

function SignUp({ signOut, user, client }) {
  const [times, setTimes] = react.useState([]);
  const [error, setError] = react.useState(null);
  const [currentSignedInUser, setCurrentSignedInUser] = react.useState({user_id:"",email_address:"",jwtToken:""});

  react.useEffect(() => {
    setCurrentSignedInUser({user_id:user.attributes.sub,email_address:user.attributes.email,jwtToken:user.signInUserSession.idToken.jwtToken})
  },[user]);

  react.useEffect(() => {
    client
      .get('/getAvailableAppointments')
      .then((response) => {
        const times = JSON.parse(response.data.body).availableAppointments.map(
          (x) => new Date(x)
        );
        setTimes(times);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  if (error) return `Error: ${error.message}`;

  return (
    <>
      <div className="WebContainer background">
        <div className="header">
          <Link to="/" id="link">
            <img className="a.logo" src={logo} width="250" height="50" alt="" />
          </Link>

          <div className="header-centre-wrapper">
            <Link to="/GetAppointments" className="header-centre">
              <h href="#Bookings">Your Bookings</h>
            </Link>
          </div>
          <div className="header-right-wrapper">
            <Link role="button" to="/" className="header-right" onClick={signOut}>
                <a href="#SignOut">Sign Out</a>
            </Link>
          </div>
        </div>
        <CreateAppointment times={times} user={currentSignedInUser} client={client} />
      </div>
    </>
  );
}
export default SignUp;
