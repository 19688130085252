import "./layout/main/main.css";
import logo from "./resources/images/logo.png";
import { Link, useLocation } from "react-router-dom";
import react from "react";
import { formatRFC7231 } from "date-fns";

function GetAppointments({ signOut, user, client }) {
  const location = useLocation();
  const [bookingId, setBookingId] = react.useState(null);
  const [error, setError] = react.useState(null);

  react.useEffect(() => {
    if (location.state) {
      setBookingId(location.state.bookingId);
    }
  }, []);

  const [bookedAppointments, setBookedAppointments] = react.useState(null);

  react.useEffect(() => {
    client
      .post("/getBookedAppointments", {
        user_id: user.attributes.sub,
      })
      .then((response) => {
        setBookedAppointments(response.data);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  return (
    <>
      <div className="WebContainer background">
        <div className="header">
          <Link to="/" id="link">
            <img className="a.logo" src={logo} width="250" height="50" alt="" />
          </Link>
          <div className="header-centre-wrapper">
            <Link to="/SignUp" id="link" className="header-centre">
              <h href="#SignUp">Schedule Appointment</h>
            </Link>
          </div>
          <div className="header-right-wrapper">
            <Link
              role="button"
              to="/"
              className="header-right"
              onClick={signOut}
            >
              <a href="#SignOut">Sign Out</a>
            </Link>
          </div>
        </div>
        <div className="home-page">
          <h2> Your Booked Appointments </h2>
          {bookingId && (
            <h3>
              Booking Successful - Your Booking Reference Is: {bookingId} <br />
            </h3>
          )}
          {bookedAppointments &&
             bookedAppointments.sort(function(a,b){
              return b.id - a.id;
            }).map((item) => {
              return (
                <div>
                  <div class="card">
                    <div class="container">
                      <h4>
                        <b>Appointment Reference: {item.id}</b>
                      </h4>
                      <p>
                        <b>Medical Professional:</b>{" "}
                        {item.title +
                          " " +
                          item.given_name +
                          " " +
                          item.family_name}
                      </p>
                      <p><b>Surgery: </b>{item.surgery_name}</p>
                      <p>
                      <b>Appointment Time:</b>{" "}
                        {formatRFC7231(Date.parse(item.appointment_date_time))}
                      </p>
                      <p>
                        <b> Appointment Duration: </b>{item.appointment_duration}{" "}
                        minutes
                      </p>
                    </div>
                  </div>
                  <br />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default GetAppointments;
