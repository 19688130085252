import DatePicker from "react-datepicker";
import react from "react";
import { parseISO, formatISO9075 } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import "./layout/main/main.css";
import { useNavigate } from "react-router-dom";

function CreateAppointment({ times, user, client }) {
  const userId = user.user_id;
  let navigate = useNavigate();

  const [error, setError] = react.useState(null);
  const [appointmentDetails, setAppointmentDetails] = react.useState({
    doctor_id: "",
    surgery_id: "",
    appointment_date_time: "",
  });
  const [surgeries, setSurgeries] = react.useState([{ id: 0, surgery_name: "Select a surgery" },]);

  react.useEffect(() => {
    client
      .get("/getSurgeries")
      .then((response) => {
        response.data.unshift({ id: 0, surgery_name: "Select a surgery" });
        setSurgeries(response.data);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  if (error) return `Error: ${error.message}`;

  const [doctors, setDoctors] = react.useState([
    {
      id: 0,
      title: "",
      given_name: "",
      family_name: "Select a health professional",
    },
  ]);
  react.useEffect(() => {
    client
      .post("/getDoctorsFromSurgeries", {
        surgery_id: appointmentDetails.surgery_id,
      })
      .then((response) => {
        response.data.unshift({
          id: 0,
          title: "",
          given_name: "",
          family_name: "Select a health professional",
        });
        setDoctors(response.data);
      })
      .catch((error) => {
        setError(error);
      });
  }, [appointmentDetails.surgery_id]);

  const [startDate, setStartDate] = react.useState(new Date());
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAppointmentDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function DateTimePicker(dates) {
    return (
      <DatePicker
        className="datepicker-date-input-field"
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        showTimeSelect
        popperPlacement="bottom"
        popperProps={{ strategy: "fixed" }}
        timeIntervals={15}
        includeTimes={dates}
        includeDates={dates}
        dateFormat="MMMM d, yyyy h:mm aa"
      />
    );
  }

  function convertDateTime(selectedDateTime) {
    return formatISO9075(parseISO(selectedDateTime.toISOString(), new Date()));
  }

  async function CreateAppointment() {
    client
      .post("/createAppointment", {
        user_id: userId,
        doctor_id: appointmentDetails.doctor_id,
        surgery_id: appointmentDetails.surgery_id,
        email_address: user.email_address,
        appointment_date_time: convertDateTime(startDate),
      })
      .then((response) => {
        if(response.data.records!==null){
          console.log(`Returned id is : ` + response.data.records[0].id)
          navigate('/GetAppointments',{state: {userId:userId,bookingId:response.data.records[0].id,jwtToken:user.jwtToken}});
        }
        console.log(`Response is ` + JSON.stringify(response.data.records));
      })
      .catch((error) => {
        setError(error);
      });
  }

    return (
      <>
        <div className="home-page">
          <h2>
            <div>
              <p>Select a surgery</p>
              <select
                value={surgeries.id}
                name="surgery_id"
                className="custom-dropdown"
                onChange={handleChange}
              >
                {surgeries.map((item) => {
                  return <option value={item.id}>{item.surgery_name}</option>;
                })}
              </select>

              <p>Select a health professional</p>
              <select
                value={doctors.id}
                name="doctor_id"
                onChange={handleChange}
                className="custom-dropdown"
              >
                {doctors.map((item) => {
                  return (
                    <option value={item.id}>
                      {item.title +
                        " " +
                        item.given_name +
                        " " +
                        item.family_name}
                    </option>
                  );
                })}
              </select>
              <p>Select a date and time that suits you</p>
            </div>
          </h2>

          {DateTimePicker(times)}
          <div className="confirm-button-wrapper">
            <button
              className="confirm-button"
              onClick={async () => {
                await CreateAppointment();
              }}
            >
              {" "}
              Confirm{" "}
            </button>
          </div>
        </div>
      </>
    );
}

export default CreateAppointment;
